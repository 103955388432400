import React from 'react';
import { ContactItem } from '../../components';
import './ContactsList.scss';
import { Contact } from '../../pages/Contacts';
import clsx from 'clsx';

type ContactListProps = {
  place?: string;
  contactsData?: Contact[];
};

const footerContacts = [
  {
    svgIconElement: (
      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
        <path
          d='M6.98356 7.37767C7.56356 8.58569 8.35422 9.71789 9.35553 10.7192C10.3568 11.7205 11.4891 12.5112 12.6971 13.0912C12.801 13.1411 12.8529 13.166 12.9187 13.1852C13.1523 13.2533 13.4392 13.2044 13.637 13.0627C13.6927 13.0228 13.7403 12.9752 13.8356 12.8799C14.1269 12.5886 14.2726 12.4429 14.4191 12.3477C14.9715 11.9885 15.6837 11.9885 16.2361 12.3477C16.3825 12.4429 16.5282 12.5886 16.8196 12.8799L16.9819 13.0423C17.4248 13.4852 17.6462 13.7066 17.7665 13.9444C18.0058 14.4174 18.0058 14.976 17.7665 15.4489C17.6462 15.6867 17.4248 15.9082 16.9819 16.351L16.8506 16.4824C16.4092 16.9238 16.1886 17.1444 15.8885 17.313C15.5556 17.5 15.0385 17.6345 14.6567 17.6333C14.3126 17.6323 14.0774 17.5655 13.607 17.432C11.0792 16.7146 8.69387 15.3608 6.70388 13.3709C4.7139 11.3809 3.36017 8.99557 2.6427 6.46774C2.50919 5.99737 2.44244 5.76218 2.44141 5.41806C2.44028 5.03621 2.57475 4.51913 2.76176 4.18621C2.9303 3.88618 3.15098 3.66551 3.59233 3.22416L3.72369 3.09279C4.16656 2.64992 4.388 2.42849 4.62581 2.3082C5.09878 2.06898 5.65734 2.06898 6.1303 2.3082C6.36812 2.42849 6.58955 2.64992 7.03242 3.09279L7.19481 3.25518C7.48615 3.54652 7.63182 3.69219 7.72706 3.83867C8.08622 4.39108 8.08622 5.10323 7.72706 5.65564C7.63182 5.80212 7.48615 5.94779 7.19481 6.23913C7.09955 6.33439 7.05192 6.38202 7.01206 6.4377C6.87038 6.63556 6.82146 6.92244 6.88957 7.15607C6.90873 7.22181 6.93367 7.27376 6.98356 7.37767Z'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
    text: '+7 (999) 9999999',
  },
  {
    svgIconElement: (
      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
        <path
          d='M1.66675 5.83331L8.47085 10.5962C9.02182 10.9819 9.29731 11.1747 9.59697 11.2494C9.86166 11.3154 10.1385 11.3154 10.4032 11.2494C10.7029 11.1747 10.9783 10.9819 11.5293 10.5962L18.3334 5.83331M5.66675 16.6666H14.3334C15.7335 16.6666 16.4336 16.6666 16.9684 16.3942C17.4388 16.1545 17.8212 15.772 18.0609 15.3016C18.3334 14.7668 18.3334 14.0668 18.3334 12.6666V7.33331C18.3334 5.93318 18.3334 5.23312 18.0609 4.69834C17.8212 4.22793 17.4388 3.84548 16.9684 3.6058C16.4336 3.33331 15.7335 3.33331 14.3334 3.33331H5.66675C4.26662 3.33331 3.56655 3.33331 3.03177 3.6058C2.56137 3.84548 2.17892 4.22793 1.93923 4.69834C1.66675 5.23312 1.66675 5.93318 1.66675 7.33331V12.6666C1.66675 14.0668 1.66675 14.7668 1.93923 15.3016C2.17892 15.772 2.56137 16.1545 3.03177 16.3942C3.56655 16.6666 4.26662 16.6666 5.66675 16.6666Z'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
    text: 'composersirk@mail.ru',
  },
  {
    svgIconElement: (
      <svg width='20' height='20' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_119_3822)'>
          <path
            d='M2.86878 5.08671H2.78285L2.78464 5.17262C2.83095 7.39524 3.40993 9.18552 4.47492 10.4214C5.5416 11.6593 7.08753 12.3313 9.04545 12.3313H9.26507H9.34923V12.2471V9.81266C10.6878 9.98535 11.703 10.9821 12.1174 12.2728L12.1361 12.3313H12.1975H14.2092H14.3196L14.2904 12.2248C13.7572 10.2834 12.3984 9.1674 11.4729 8.69069C12.3821 8.11501 13.5717 6.86148 13.867 5.18547L13.8844 5.08671H13.7841H11.9566H11.8929L11.8756 5.14796C11.6811 5.83555 11.2951 6.49302 10.8207 6.99265C10.3729 7.46431 9.8532 7.78832 9.34923 7.8723V5.17087V5.08671H9.26507H7.4375H7.35334V5.17087V9.96173C6.28515 9.63325 4.95504 8.28755 4.89377 5.16921L4.89214 5.08671H4.80962H2.86878ZM0.0841584 8.16C0.0841584 6.23429 0.0843371 4.79848 0.232784 3.69435C0.380904 2.59264 0.675254 1.83377 1.25451 1.25451C1.83377 0.675254 2.59264 0.380904 3.69435 0.232784C4.79848 0.0843371 6.23429 0.0841584 8.16 0.0841584H8.84C10.7657 0.0841584 12.2015 0.0843371 13.3057 0.232784C14.4074 0.380904 15.1662 0.675254 15.7455 1.25451C16.3247 1.83377 16.6191 2.59264 16.7672 3.69435C16.9157 4.79848 16.9158 6.23429 16.9158 8.16V8.84C16.9158 10.7657 16.9157 12.2015 16.7672 13.3057C16.6191 14.4074 16.3247 15.1662 15.7455 15.7455C15.1662 16.3247 14.4074 16.6191 13.3057 16.7672C12.2015 16.9157 10.7657 16.9158 8.84 16.9158H8.16C6.23429 16.9158 4.79848 16.9157 3.69435 16.7672C2.59264 16.6191 1.83377 16.3247 1.25451 15.7455C0.675254 15.1662 0.380904 14.4074 0.232784 13.3057C0.0843371 12.2015 0.0841584 10.7657 0.0841584 8.84V8.16Z'
            fill='#fff'
            stroke='#62767C'
            strokeWidth='0.168317'
          />
        </g>
        <defs>
          <clipPath id='clip0_119_3822'>
            <rect width='17' height='17' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ),
    text: 'vk.com/unioncomposers',
  },
];

export const ContactsList = ({ place, contactsData }: ContactListProps) => {
  const contacts = contactsData ? contactsData : footerContacts;
  return (
    <ul className={clsx('contacts-list', place === 'contacts' && 'contacts-list_place_contacts')}>
      {contacts.map((contact, index) => (
        <li key={index} className='contacts-list__item'>
          <ContactItem {...contact} place={place} />
        </li>
      ))}
    </ul>
  );
};
