import React from 'react';
import './VKLink.scss';

type VKLinkProps = {
  place?: string;
};

export const VKLink = ({ place }: VKLinkProps) => {
  return (
    <a className='vk-link' href='https://vk.com/public170806854' target='_blank' rel='noreferrer'>
      <svg
        className={`vk-link__icon ${place === 'footer' ? 'vk-link__icon_place_footer' : ''}`}
        width='17'
        height='17'
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_119_3822)'>
          <path
            d='M2.86878 5.08671H2.78285L2.78464 5.17262C2.83095 7.39524 3.40993 9.18552 4.47492 10.4214C5.5416 11.6593 7.08753 12.3313 9.04545 12.3313H9.26507H9.34923V12.2471V9.81266C10.6878 9.98535 11.703 10.9821 12.1174 12.2728L12.1361 12.3313H12.1975H14.2092H14.3196L14.2904 12.2248C13.7572 10.2834 12.3984 9.1674 11.4729 8.69069C12.3821 8.11501 13.5717 6.86148 13.867 5.18547L13.8844 5.08671H13.7841H11.9566H11.8929L11.8756 5.14796C11.6811 5.83555 11.2951 6.49302 10.8207 6.99265C10.3729 7.46431 9.8532 7.78832 9.34923 7.8723V5.17087V5.08671H9.26507H7.4375H7.35334V5.17087V9.96173C6.28515 9.63325 4.95504 8.28755 4.89377 5.16921L4.89214 5.08671H4.80962H2.86878ZM0.0841584 8.16C0.0841584 6.23429 0.0843371 4.79848 0.232784 3.69435C0.380904 2.59264 0.675254 1.83377 1.25451 1.25451C1.83377 0.675254 2.59264 0.380904 3.69435 0.232784C4.79848 0.0843371 6.23429 0.0841584 8.16 0.0841584H8.84C10.7657 0.0841584 12.2015 0.0843371 13.3057 0.232784C14.4074 0.380904 15.1662 0.675254 15.7455 1.25451C16.3247 1.83377 16.6191 2.59264 16.7672 3.69435C16.9157 4.79848 16.9158 6.23429 16.9158 8.16V8.84C16.9158 10.7657 16.9157 12.2015 16.7672 13.3057C16.6191 14.4074 16.3247 15.1662 15.7455 15.7455C15.1662 16.3247 14.4074 16.6191 13.3057 16.7672C12.2015 16.9157 10.7657 16.9158 8.84 16.9158H8.16C6.23429 16.9158 4.79848 16.9157 3.69435 16.7672C2.59264 16.6191 1.83377 16.3247 1.25451 15.7455C0.675254 15.1662 0.380904 14.4074 0.232784 13.3057C0.0843371 12.2015 0.0841584 10.7657 0.0841584 8.84V8.16Z'
            fill='#62767C'
            stroke='#62767C'
            strokeWidth='0.168317'
          />
        </g>
        <defs>
          <clipPath id='clip0_119_3822'>
            <rect width='17' height='17' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};
