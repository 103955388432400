import React from 'react';
import './MailLink.scss';

type MailLinkProps = {
  place?: string;
};

export const MailLink = ({ place }: MailLinkProps) => {
  return (
    <a className='mail-link' href='mailto:composersirk@mail.ru' target='_blank' rel='noreferrer'>
      <svg
        className={`mail-link__icon ${place === 'footer' ? 'mail-link__icon_place_footer' : ''}`}
        width='20'
        height='17'
        viewBox='0 0 20 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        {place !== 'footer' && (
          <path
            d='M1 5.67335C1 4.18921 1 3.44714 1.28883 2.88027C1.5429 2.38165 1.9483 1.97625 2.44693 1.72218C3.01379 1.43335 3.75586 1.43335 5.24 1.43335H14.4267C15.9108 1.43335 16.6529 1.43335 17.2197 1.72218C17.7184 1.97625 18.1238 2.38165 18.3778 2.88027C18.6667 3.44714 18.6667 4.18921 18.6667 5.67335V11.3267C18.6667 12.8108 18.6667 13.5529 18.3778 14.1198C18.1238 14.6184 17.7184 15.0238 17.2197 15.2779C16.6529 15.5667 15.9108 15.5667 14.4267 15.5667H5.24C3.75586 15.5667 3.01379 15.5667 2.44693 15.2779C1.9483 15.0238 1.5429 14.6184 1.28883 14.1198C1 13.5529 1 12.8108 1 11.3267V5.67335Z'
            fill='white'
          />
        )}
        <path
          d='M1 4.08335L8.21234 9.13199C8.79638 9.54081 9.0884 9.74523 9.40603 9.8244C9.68661 9.89434 9.98006 9.89434 10.2606 9.8244C10.5783 9.74523 10.8703 9.54082 11.4543 9.13199L18.6667 4.08335M5.24 15.5667H14.4267C15.9108 15.5667 16.6529 15.5667 17.2197 15.2778C17.7184 15.0238 18.1238 14.6184 18.3778 14.1198C18.6667 13.5529 18.6667 12.8108 18.6667 11.3267V5.67335C18.6667 4.18921 18.6667 3.44714 18.3778 2.88027C18.1238 2.38165 17.7184 1.97625 17.2197 1.72218C16.6529 1.43335 15.9108 1.43335 14.4267 1.43335H5.24C3.75586 1.43335 3.01379 1.43335 2.44693 1.72218C1.9483 1.97625 1.5429 2.38165 1.28883 2.88027C1 3.44714 1 4.18921 1 5.67335V11.3267C1 12.8108 1 13.5529 1.28883 14.1198C1.5429 14.6184 1.9483 15.0238 2.44693 15.2778C3.01379 15.5667 3.75586 15.5667 5.24 15.5667Z'
          stroke='#62767C'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </a>
  );
};
